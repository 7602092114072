import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Meal } from "@kiwicom/orbit-components/icons";
import DocLayout from "../components/DocLayout";
import Palette from "../components/Palette";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Callout = makeShortcode("Callout");
const ImageContainer = makeShortcode("ImageContainer");
const FancyLink = makeShortcode("FancyLink");
const Guideline = makeShortcode("Guideline");
const GuidelineImages = makeShortcode("GuidelineImages");
const DoImage = makeShortcode("DoImage");
const DontImage = makeShortcode("DontImage");
const GuidelinesSideBySide = makeShortcode("GuidelinesSideBySide");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const ReactExample = makeShortcode("ReactExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  location
}) => {
  return <DocLayout description="Examples of our components shared across MDX docs files." title="MDX examples" location={location} path="/mdx-examples/">
      {children}
    </DocLayout>;
};
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {/* vale off */}

    {/* vale on */}
    <p>{`This page includes examples of all components shared across our docs files.
See them all in action in the built page
or view the source to see how use them.`}</p>
    <h2>{`h2 Heading`}</h2>
    <p>{`Text under a second-level heading.`}</p>
    <h3>{`h3 Heading`}</h3>
    <p>{`Text under a third-level heading.`}</p>
    <h4>{`h4 Heading`}</h4>
    <p>{`Text under a fourth-level heading.`}</p>
    <h5>{`h5 Heading`}</h5>
    <p>{`Text under a fifth-level heading.`}</p>
    <h6>{`h6 Heading`}</h6>
    <p>{`Text under a sixth-level heading.`}</p>
    <h2>{`Horizontal Rules`}</h2>
    <hr></hr>
    <h2>{`Typographic replacements`}</h2>
    <p>{`©`}{` `}{`®`}{` `}{`™`}{` `}{`¶`}{` `}{`§`}{` `}{`±`}</p>
    <p>{`→`}{` `}{`←`}</p>
    <p>{`Ellipses: test.. test… test… test?… test!…`}</p>
    <p>{`Dashes: – —`}</p>
    <p>{`Quotes: “Smartypants, double quotes” and ‘single quotes’`}</p>
    <h2>{`Emphasis`}</h2>
    <p>{`This is `}<strong parentName="p">{`bold text`}</strong></p>
    <p>{`This is `}<em parentName="p">{`italic text`}</em></p>
    <p><del parentName="p">{`Strike through`}</del></p>
    <h2>{`Lists`}</h2>
    <h3>{`Unordered`}</h3>
    <ul>
      <li parentName="ul">{`Create a list by starting a line with `}<inlineCode parentName="li">{`-`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Sub-lists are made by indenting 2 spaces:`}
        <ul parentName="li">
          <li parentName="ul">{`Keep going, just with the indentation the same for each list:`}
            <ul parentName="li">
              <li parentName="ul">{`Next item`}</li>
              <li parentName="ul">{`And another`}</li>
              <li parentName="ul">{`And another`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Very easy!`}</li>
    </ul>
    <h3>{`Ordered`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The first item in the top-level list`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The second item in the top-level list`}</p>
        <ol parentName="li">
          <li parentName="ol">{`The first item in a nested list`}</li>
          <li parentName="ol">{`The second item in a nested list`}</li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The third item in the top-level list`}</p>
      </li>
    </ol>
    <h3>{`Items with multiple paragraphs`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The first paragraph.`}</p>
        <p parentName="li">{`The second paragraph.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Another item.`}</p>
      </li>
    </ul>
    <h2>{`Code`}</h2>
    <p>{`Inline `}<inlineCode parentName="p">{`code`}</inlineCode></p>
    <p>{`Code blocks`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-txt"
      }}>{`Sample text here...
`}</code></pre>
    <p>{`Syntax highlighting`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const foo = bar => ++bar;

console.log(foo(5));
`}</code></pre>
    <h2>{`Callouts`}</h2>
    <p>{`You can use various callouts using properties from `}<a parentName="p" {...{
        "href": "/components/information/alert/react/#props"
      }}>{`our Alert component`}</a>{`.`}</p>
    <Callout title="This is an informational callout." mdxType="Callout">
      <p>{`It should present additional useful information.`}</p>
    </Callout>
    <Callout type="success" title="This is a success callout." mdxType="Callout">
      <p>{`You can present tips for additional success or mark progress through a guide.`}</p>
    </Callout>
    <Callout type="warning" title="This is a warning callout." mdxType="Callout">
      <p>{`It can warn about potential dangers that might come with specific choices.`}</p>
    </Callout>
    <Callout type="critical" title="This is a critical callout." mdxType="Callout">
      <p>{`It should only present information that could end up destroying previous progress/data.`}</p>
    </Callout>
    <Callout title="This is a callout with only a title" mdxType="Callout" />
    <Callout title="This is a callout with only a title and a custom icon" icon={<Meal ariaLabel="Hot food." mdxType="Meal" />} mdxType="Callout" />
    <h2>{`Tables`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`orbit-components`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All our React components along with API documentation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`orbit-design-tokens`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All visual UI attributes available as tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`eslint-plugin-orbit-components`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ESLint rules to enforce best practices for our components.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Aligned columns`}</h3>
    <p>{`In addition to the default of left alignment, columns can also be right or center aligned.
Set alignment with colons in the header row.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`orbit-components`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`All our React components along with API documentation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`orbit-design-tokens`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`All visual UI attributes available as tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}>{`eslint-plugin-orbit-components`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`ESLint rules to enforce best practices for our components.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Links`}</h2>
    <p>{`External links`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/kiwicom/orbit"
      }}>{`link text`}</a></p>
    <p>{`Internal links`}</p>
    <p><a parentName="p" {...{
        "href": "."
      }}>{`link text`}</a></p>
    <p>{`Automatically converted link `}<a parentName="p" {...{
        "href": "https://github.com/kiwicom/orbit"
      }}>{`https://github.com/kiwicom/orbit`}</a></p>
    <h2>{`Images`}</h2>
    <h3>{`Plain Images`}</h3>
    <p><img parentName="p" {...{
        "src": "../images/rocket.jpg",
        "alt": "Orbit rocket"
      }}></img></p>
    <h3>{`Image with caption`}</h3>
    <p><img parentName="p" {...{
        "src": "../images/rocket.jpg",
        "alt": "Orbit rocket",
        "title": "Blast into Orbit"
      }}></img></p>
    <h3>{`Images by reference`}</h3>
    <p>{`You can also add images with a footnote-style syntax.`}</p>
    <p><img parentName="p" {...{
        "src": "../images/rocket.jpg",
        "alt": "Alt text",
        "title": "Blast into Orbit"
      }}></img></p>
    <p>{`Use a reference at the end of the document to define the URL location.`}</p>
    <h3>{`Figma image`}</h3>
    <p>{`TODO.`}</p>
    <h3>{`Image container`}</h3>
    <h4>{`Alignment`}</h4>
    <ImageContainer align="left" size="small" mdxType="ImageContainer">
      <p><img parentName="p" {...{
          "src": "../images/rocket.jpg",
          "alt": "Orbit rocket"
        }}></img></p>
    </ImageContainer>
    <ImageContainer align="center" size="small" mdxType="ImageContainer">
      <p><img parentName="p" {...{
          "src": "../images/rocket.jpg",
          "alt": "Orbit rocket"
        }}></img></p>
    </ImageContainer>
    <ImageContainer align="right" size="small" mdxType="ImageContainer">
      <p><img parentName="p" {...{
          "src": "../images/rocket.jpg",
          "alt": "Orbit rocket"
        }}></img></p>
    </ImageContainer>
    <h4>{`Size`}</h4>
    <h5>{`Small`}</h5>
    <ImageContainer size="small" mdxType="ImageContainer">
      <p><img parentName="p" {...{
          "src": "../images/rocket.jpg",
          "alt": "Orbit rocket"
        }}></img></p>
    </ImageContainer>
    <h5>{`Medium`}</h5>
    <ImageContainer size="medium" mdxType="ImageContainer">
      <p><img parentName="p" {...{
          "src": "../images/rocket.jpg",
          "alt": "Orbit rocket"
        }}></img></p>
    </ImageContainer>
    <h5>{`Large`}</h5>
    <ImageContainer mdxType="ImageContainer">
      <p><img parentName="p" {...{
          "src": "../images/rocket.jpg",
          "alt": "Orbit rocket"
        }}></img></p>
    </ImageContainer>
    <h4>{`Border`}</h4>
    <ImageContainer size="small" border mdxType="ImageContainer">
      <p><img parentName="p" {...{
          "src": "../images/rocket.jpg",
          "alt": "Orbit rocket"
        }}></img></p>
    </ImageContainer>
    <h2>{`Definition Lists`}</h2>
    <dl><dt parentName="dl">{`Orbit`}</dt><dd parentName="dl">{`An open source design system for your next travel project.`}</dd><dt parentName="dl">{`Design system`}</dt><dd parentName="dl">{`Components, styles, and patterns to create beautiful consistent designs at scale.`}</dd></dl>
    <h2>{`Fancy links`}</h2>
    <p>{`Tiles that span the text area and show icons the links.`}</p>
    <FancyLink title="Orbit GitHub repo" href="https://github.com/kiwicom/orbit/" icon="github" mdxType="FancyLink" />
    <p>{`Choose from the existing icons or import your own.`}</p>
    <FancyLink title="Eat at Orbit" href="https://orbit.kiwi/" icon={<Meal mdxType="Meal" />} mdxType="FancyLink" />
    <h2>{`Guidelines`}</h2>
    <h3>{`Guideline do`}</h3>
    <Guideline type="do" title="Use secondary small buttons for additional actions on the page" mdxType="Guideline">
      <p>{`The secondary small button is great for actions in `}<a parentName="p" {...{
          "href": "/components/structure/card/"
        }}>{`cards`}</a>{`
as it stands out from the interface but doesn’t take so much attention.`}</p>
    </Guideline>
    <h3>{`Guideline don’t`}</h3>
    <Guideline type="dont" title="Don’t use Product colors to highlight information" mdxType="Guideline">
      <p>{`Because Product colors are used for actions,
it may cause confusion when they’re used to highlight some information or text.`}</p>
    </Guideline>
    <h3>{`Guideline with one image`}</h3>
    <Guideline type="dont" title="Don't combine with text link" mdxType="Guideline">
      <p>{`If you’d like to have an action, consider an `}<a parentName="p" {...{
          "href": "/components/information/alert/"
        }}>{`alert`}</a>{`
or a `}<a parentName="p" {...{
          "href": "/components/action/button/"
        }}>{`button`}</a>{` instead.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "318px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.053763440860216%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6klEQVQI1yWOO0/CYBSGGwNUES+IF36AsxhJHOUfYGJCDANGBwfj7g5tgQKTiwgDOnmL0Bj8Tz3Hln51NOG85tP1yfNeDLJNhzub8BsJIdsUaq2I30jAry+AWqugZgbkpEFWCuxm/5mVBNkmyFnSXMhJCzWXdcYzyF5E7F1AvZ3O1bgGNTmDeq0gej5G9FSGmtQQe+eIXk4wGx0hnl5pF7OHEr4/r6Heq4g/LkV36GHDt5I31F7/4e42uLsl1F4T7u3g63b37x27OXAnB3Y3wL08wuEhgn4Bwd0ewkER4f2BhIOiBP197T/+ArwJnTfG39SUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "An alert with the message \"your booking failed\" and a button labeled \"get details\".",
            "title": "",
            "src": "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png",
            "srcSet": ["/static/98ff1badb4cd702981158d74d2218abb/679d2/Badge-w-action.png 186w", "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png 318w"],
            "sizes": "(max-width: 318px) 100vw, 318px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </Guideline>
    <Guideline type="do" title="Have one primary action " mdxType="Guideline">
      <p>{`Every screen should have only one button that represents the most important action:
leading the user to the next step.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "157.5268817204301%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAADkUlEQVQ4y+1VW28bRRQeVUGiSDzxguCv8Mw/4Yk/wQMipaUFUSqEKEIqoQgaGgWEhECtUzWJa68vqe3ESRy7sb332cvsdWZ2Lgu7Tl0nDRWir3z6tDrnfOecOXseZoAQYqpDA/r5acgSZ4w8zw3b6x9NRhMDughwKSNKOReLlYxzxhnnnDE2q5mDc8E4F1xwzkHEsrpnPnKNaRLO23ueX1eaSqNVrSn3KhvQcefSIoCQOReCCcGFkDKfJ3DOhRCkhCikE+Qyl0VikQmElEIUoTzPhZBFr2KkopcozyrEEkJIzqXgYmYLIUGYYB2iieEMJ/rUdKYGNN3A8kLLC003OFYt0w0SQvdH2lh3DMfXIdo7UlXLdYMIhDEeaXZvMN5Wdmrt7pbSnpT1hoN06HcPRqrl4Yz9tqHUOwMDes3d4cr6xhPVtFwEMM3CFMeEphmPSZYQFmMapQTFKYrTKCVBnKaEJoRGKQ5i/DSIE0JBgskT1Z4YcKyZmuVOTXismarpQBQV9Iuv7YfQD+fuLIKiFARx2tgdDsbGVr3d6PQbnX5ls1Zr93SIntFBp1yINNt3UARSkoUJjjHBGU8pSykjXKYZD+L0BURRkmACYkyOdUeHvlquemo8HduPXkDbK8cOE/zo8WFvMGl29mqt7mat9aCqNDp7mu0X0/4DNXgyNi3XS8OEhAkJElwY5T6LhUfn0w+TOCUgTsnhsTExHB16lotMx7dcpNueDj3TQQ6KzyX0oyDGwAmi+/Vesz/882H13lZts7Gz3epsNnbub9crVWWk21PoT2zvDMeWa/khyJlkSSYwl4QLzCThksy+PKcip3xmn8NMgIqtftRXPj1sXz1oLfKT/eZyX1nuNy7vN89I1w5ay33lrjoE71V/B19/sLRyBXz38TPeunTh9tU312++sfbVaz9+Bm5dWlQvrFwG3374zh+3wfvNCvj+ysXVG6/cuT7n0p3rF1dvvP3rN2+t33z97pdLP32+qL66+gX44dq7D9aAgZO2D7vI6ZzmYx82Pavp2Ts+7Dyntn04jkOQvwT+L/5vxfK5t+bfuC93spSScm6kcXH7lz0zxnq7exlj5Y0vGec2dAilMs95+ViEYcRYcfcXJ2POm57F5MlbhzH5ee2XOElmLqW0092NoqgcuIh0uj0UBCdjWyQ9ipBHySzbdb3pVIWOM/s9TTdUTZ+qGsuyWeuDw4FumH8X/wV/Ttn5QN/KkAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "One primary button on a screen stands out.",
            "title": "",
            "src": "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/37523/One-primary-action.png",
            "srcSet": ["/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/679d2/One-primary-action.png 186w", "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/67a5d/One-primary-action.png 373w", "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/37523/One-primary-action.png 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </Guideline>
    <h3>{`Guideline with only image and title`}</h3>
    <Guideline type="dont" title="Don't combine with text link" mdxType="Guideline">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "318px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.053763440860216%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6klEQVQI1yWOO0/CYBSGGwNUES+IF36AsxhJHOUfYGJCDANGBwfj7g5tgQKTiwgDOnmL0Bj8Tz3Hln51NOG85tP1yfNeDLJNhzub8BsJIdsUaq2I30jAry+AWqugZgbkpEFWCuxm/5mVBNkmyFnSXMhJCzWXdcYzyF5E7F1AvZ3O1bgGNTmDeq0gej5G9FSGmtQQe+eIXk4wGx0hnl5pF7OHEr4/r6Heq4g/LkV36GHDt5I31F7/4e42uLsl1F4T7u3g63b37x27OXAnB3Y3wL08wuEhgn4Bwd0ewkER4f2BhIOiBP197T/+ArwJnTfG39SUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "An alert with the message \"your booking failed\" and a button labeled \"get details\".",
            "title": "",
            "src": "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png",
            "srcSet": ["/static/98ff1badb4cd702981158d74d2218abb/679d2/Badge-w-action.png 186w", "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png 318w"],
            "sizes": "(max-width: 318px) 100vw, 318px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </Guideline>
    <h3>{`Guideline with two images`}</h3>
    <Guideline type="dont" title="Don't combine with text link" mdxType="Guideline">
      <p>{`If you’d like to have an action, consider an `}<a parentName="p" {...{
          "href": "/components/information/alert/"
        }}>{`alert`}</a>{`
or a `}<a parentName="p" {...{
          "href": "/components/action/button/"
        }}>{`button`}</a>{` instead.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "318px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.053763440860216%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6klEQVQI1yWOO0/CYBSGGwNUES+IF36AsxhJHOUfYGJCDANGBwfj7g5tgQKTiwgDOnmL0Bj8Tz3Hln51NOG85tP1yfNeDLJNhzub8BsJIdsUaq2I30jAry+AWqugZgbkpEFWCuxm/5mVBNkmyFnSXMhJCzWXdcYzyF5E7F1AvZ3O1bgGNTmDeq0gej5G9FSGmtQQe+eIXk4wGx0hnl5pF7OHEr4/r6Heq4g/LkV36GHDt5I31F7/4e42uLsl1F4T7u3g63b37x27OXAnB3Y3wL08wuEhgn4Bwd0ewkER4f2BhIOiBP197T/+ArwJnTfG39SUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "An alert with the message \"your booking failed\" and a button labeled \"get details\".",
            "title": "",
            "src": "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png",
            "srcSet": ["/static/98ff1badb4cd702981158d74d2218abb/679d2/Badge-w-action.png 186w", "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png 318w"],
            "sizes": "(max-width: 318px) 100vw, 318px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "656px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.64516129032258%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABF0lEQVQY03XP/UuDQBgHcP//f6OCIIJeRhDBiMpV62UvTnCbiTo1mt6pyOZ05/ONO6NgrB8+PBz35XvPaWVo1PnsDptPk8pgjDIysF4MlU00wcp9RRmMILgHkfr/oXZ6tVbYOiL9gArnGdy4Rmbdgo2ukAw76hwPLsDNm7YwCyCyBZos2EeVapSHhNUSDbNJxBZa0x8WiNug1EHD5q1kiu2XuYvkFMwhTaQeydfr4IUq9x6Vq6NyH5Taf0QxuQTrH4G/HYP1D5EOTlB5+i6S+W08lYW+WrcO39WlLJEqT84nrO0ucuMc+fgM2fAUhdlB5fV+c3/5HolkDk0wJxXMgYhntF1a2CWSGRr+oRCXX7exJ0dt1k6/ATOpuVM1kheOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "A badge with the text: \"Booking failed. Get details.\"\" The second sentence is a link.",
            "title": "",
            "src": "/static/e92da3ac7887e05b32dfd5e8145976a5/748f4/Alert-w-action.png",
            "srcSet": ["/static/e92da3ac7887e05b32dfd5e8145976a5/679d2/Alert-w-action.png 186w", "/static/e92da3ac7887e05b32dfd5e8145976a5/67a5d/Alert-w-action.png 373w", "/static/e92da3ac7887e05b32dfd5e8145976a5/748f4/Alert-w-action.png 656w"],
            "sizes": "(max-width: 656px) 100vw, 656px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </Guideline>
    <Guideline type="do" title="Have one primary action " mdxType="Guideline">
      <p>{`Every screen should have only one button that represents the most important action:
leading the user to the next step.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "157.5268817204301%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAADkUlEQVQ4y+1VW28bRRQeVUGiSDzxguCv8Mw/4Yk/wQMipaUFUSqEKEIqoQgaGgWEhECtUzWJa68vqe3ESRy7sb332cvsdWZ2Lgu7Tl0nDRWir3z6tDrnfOecOXseZoAQYqpDA/r5acgSZ4w8zw3b6x9NRhMDughwKSNKOReLlYxzxhnnnDE2q5mDc8E4F1xwzkHEsrpnPnKNaRLO23ueX1eaSqNVrSn3KhvQcefSIoCQOReCCcGFkDKfJ3DOhRCkhCikE+Qyl0VikQmElEIUoTzPhZBFr2KkopcozyrEEkJIzqXgYmYLIUGYYB2iieEMJ/rUdKYGNN3A8kLLC003OFYt0w0SQvdH2lh3DMfXIdo7UlXLdYMIhDEeaXZvMN5Wdmrt7pbSnpT1hoN06HcPRqrl4Yz9tqHUOwMDes3d4cr6xhPVtFwEMM3CFMeEphmPSZYQFmMapQTFKYrTKCVBnKaEJoRGKQ5i/DSIE0JBgskT1Z4YcKyZmuVOTXismarpQBQV9Iuv7YfQD+fuLIKiFARx2tgdDsbGVr3d6PQbnX5ls1Zr93SIntFBp1yINNt3UARSkoUJjjHBGU8pSykjXKYZD+L0BURRkmACYkyOdUeHvlquemo8HduPXkDbK8cOE/zo8WFvMGl29mqt7mat9aCqNDp7mu0X0/4DNXgyNi3XS8OEhAkJElwY5T6LhUfn0w+TOCUgTsnhsTExHB16lotMx7dcpNueDj3TQQ6KzyX0oyDGwAmi+/Vesz/882H13lZts7Gz3epsNnbub9crVWWk21PoT2zvDMeWa/khyJlkSSYwl4QLzCThksy+PKcip3xmn8NMgIqtftRXPj1sXz1oLfKT/eZyX1nuNy7vN89I1w5ay33lrjoE71V/B19/sLRyBXz38TPeunTh9tU312++sfbVaz9+Bm5dWlQvrFwG3374zh+3wfvNCvj+ysXVG6/cuT7n0p3rF1dvvP3rN2+t33z97pdLP32+qL66+gX44dq7D9aAgZO2D7vI6ZzmYx82Pavp2Ts+7Dyntn04jkOQvwT+L/5vxfK5t+bfuC93spSScm6kcXH7lz0zxnq7exlj5Y0vGec2dAilMs95+ViEYcRYcfcXJ2POm57F5MlbhzH5ee2XOElmLqW0092NoqgcuIh0uj0UBCdjWyQ9ipBHySzbdb3pVIWOM/s9TTdUTZ+qGsuyWeuDw4FumH8X/wV/Ttn5QN/KkAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "One primary button on a screen stands out.",
            "title": "",
            "src": "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/37523/One-primary-action.png",
            "srcSet": ["/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/679d2/One-primary-action.png 186w", "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/67a5d/One-primary-action.png 373w", "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/37523/One-primary-action.png 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "157.5268817204301%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAER0lEQVQ4y+2VbUxbVRjHj4QlY8mAJcwaGC7RMbNsbAgmiy7G6Ae/mn0Y00lxC5v7sEk0xk8mmujiRregA4FCaUfpm7y0UEpfbgHLJrS3hULXMiqlGKTQCm1v33hr77nn6m3ZUgZLjPvqk1+enHOe53/Oc2+e5AEURXm8fp8/RG83lLSnFjRN+1YIp3vhjwWfPxgGEKFYPA4hla4kISQhCSEkSTKleWIQUiSEFKQghCCaiONBnzHgnV+NMDKKucUfJEyWcfO4dRS3DPxq8AcCqRBECCJEpTyNwPZ704xCNEIwkUAJElG7JSEaBDc3nBFiJhJyRghnlHBGCFc0xBALu2LhmVh4bi0SIeOOcGA6EnRFQ79HCEc44IwQ3vVV8MXkg4yOusMqwcsqAUvJe0nJy1Vwc5LkKrj5ytaDvS1f2X47PdhRqBIcUDTn9bScHujI6m44O9IPqnAdaK95sZdXqOLn97UeVgkyZbVAzAGS2y9I7uyXN+6R1bJNugqT9phGCEQ1x9T3vrQ9AGLOO0OdoHPBdXakn41jH5l0FTj2Ma5j41ilWX/JMnDJMsDGsctjg6J5JxvHKnDsYvKw0qw/b9LeddnA1w7jcZ3ozcGOUr20WCcpwSRletkpTFqkERZphEeTvlDFP/p4XaQWvqZpL1DxK80YqDJq9nXW7+u4m9fbnC1vyJE3Hurj5yqaQPutNGrStxmiGiD4/gwmAZ0eV7lRU5WssNKMVeLYhWTlV8YGn8XVsSE2rqubtYFvpvAj6raTmOS4VnRSJy7WiUswyQmd+BV127M4ohGylLxPzHpwcVSdp2hi9XCz5Y15PS0Ffa2FKkGOggtEHCCq2ZUMMQfcu/EWJgWieed7Bvk5o6bcqHns1edG1eVGzXmj5kOTdicXcN0HIyqOcxzcmLa8PdT1rqG7TC99HZOW6qUlmPSNAVmZXlaqlxUnP+QpTmGSV/vbPh0bAldMWlZvy6Eebj7TXq25Cm62ovGAgstS8lhKHvNj234AwpvpZLTfAvzvzujEQO6ZvTo29PnE/WtWw2cTw9ethuvWlDdUTwxXb51so9o6fNky2Oi2A2PA95NrsnnO0Tzn4KZwM+smt73OZatz2Rpm7Vy3I51mt6Pe9VDrnWfKBrxvM0WcbV0hvJkhuXOwtyVbwd37y49Mtbs2yTWrAUhuZ3U37Omqf0JmV31Wd0OBip+vbN2vaMrsrEuP7u3+Gchq37/fA3wba5MhvyMcsG/nYchvJZatxIot5LfviE6G/H+uRgH9HPa/+L+J0Y5Z82+2z/cyQigOoW99lUrOEUTTCZKcejSdIEnEjA1EQrjiD2zG44imIZNCR6MxkoQUQszLGxBaiWUSbc26jY1NeU/f6tpaahuPx+2OR7FYLFkwc2J3TIUjka2ylzfX3bEwEd9MZQeDhMezmBpuCKElr29xyetZXCITidTVM65Zr++vf8R/Az2IwTMW9SE8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Too many primary buttons confuse users.",
            "title": "",
            "src": "/static/bbc8700f9912c0762b652dd5de1990ff/37523/Too-many-primary-actions.png",
            "srcSet": ["/static/bbc8700f9912c0762b652dd5de1990ff/679d2/Too-many-primary-actions.png 186w", "/static/bbc8700f9912c0762b652dd5de1990ff/67a5d/Too-many-primary-actions.png 373w", "/static/bbc8700f9912c0762b652dd5de1990ff/37523/Too-many-primary-actions.png 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></p>
    </Guideline>
    <h3>{`Guideline images with separate descriptions`}</h3>
    <GuidelineImages mdxType="GuidelineImages">
      <DoImage mdxType="DoImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "720px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "157.5268817204301%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAADkUlEQVQ4y+1VW28bRRQeVUGiSDzxguCv8Mw/4Yk/wQMipaUFUSqEKEIqoQgaGgWEhECtUzWJa68vqe3ESRy7sb332cvsdWZ2Lgu7Tl0nDRWir3z6tDrnfOecOXseZoAQYqpDA/r5acgSZ4w8zw3b6x9NRhMDughwKSNKOReLlYxzxhnnnDE2q5mDc8E4F1xwzkHEsrpnPnKNaRLO23ueX1eaSqNVrSn3KhvQcefSIoCQOReCCcGFkDKfJ3DOhRCkhCikE+Qyl0VikQmElEIUoTzPhZBFr2KkopcozyrEEkJIzqXgYmYLIUGYYB2iieEMJ/rUdKYGNN3A8kLLC003OFYt0w0SQvdH2lh3DMfXIdo7UlXLdYMIhDEeaXZvMN5Wdmrt7pbSnpT1hoN06HcPRqrl4Yz9tqHUOwMDes3d4cr6xhPVtFwEMM3CFMeEphmPSZYQFmMapQTFKYrTKCVBnKaEJoRGKQ5i/DSIE0JBgskT1Z4YcKyZmuVOTXismarpQBQV9Iuv7YfQD+fuLIKiFARx2tgdDsbGVr3d6PQbnX5ls1Zr93SIntFBp1yINNt3UARSkoUJjjHBGU8pSykjXKYZD+L0BURRkmACYkyOdUeHvlquemo8HduPXkDbK8cOE/zo8WFvMGl29mqt7mat9aCqNDp7mu0X0/4DNXgyNi3XS8OEhAkJElwY5T6LhUfn0w+TOCUgTsnhsTExHB16lotMx7dcpNueDj3TQQ6KzyX0oyDGwAmi+/Vesz/882H13lZts7Gz3epsNnbub9crVWWk21PoT2zvDMeWa/khyJlkSSYwl4QLzCThksy+PKcip3xmn8NMgIqtftRXPj1sXz1oLfKT/eZyX1nuNy7vN89I1w5ay33lrjoE71V/B19/sLRyBXz38TPeunTh9tU312++sfbVaz9+Bm5dWlQvrFwG3374zh+3wfvNCvj+ysXVG6/cuT7n0p3rF1dvvP3rN2+t33z97pdLP32+qL66+gX44dq7D9aAgZO2D7vI6ZzmYx82Pavp2Ts+7Dyntn04jkOQvwT+L/5vxfK5t+bfuC93spSScm6kcXH7lz0zxnq7exlj5Y0vGec2dAilMs95+ViEYcRYcfcXJ2POm57F5MlbhzH5ee2XOElmLqW0092NoqgcuIh0uj0UBCdjWyQ9ipBHySzbdb3pVIWOM/s9TTdUTZ+qGsuyWeuDw4FumH8X/wV/Ttn5QN/KkAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "One primary button on a screen stands out.",
              "title": "",
              "src": "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/37523/One-primary-action.png",
              "srcSet": ["/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/679d2/One-primary-action.png 186w", "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/67a5d/One-primary-action.png 373w", "/static/3802a3e8d09a96032cfc8cfcdf4fb6ff/37523/One-primary-action.png 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
        <p>{`Ensure there’s only one primary action on each screen.`}</p>
      </DoImage>
      <DontImage mdxType="DontImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "720px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "157.5268817204301%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAIAAACdAM/hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAER0lEQVQ4y+2VbUxbVRjHj4QlY8mAJcwaGC7RMbNsbAgmiy7G6Ae/mn0Y00lxC5v7sEk0xk8mmujiRregA4FCaUfpm7y0UEpfbgHLJrS3hULXMiqlGKTQCm1v33hr77nn6m3ZUgZLjPvqk1+enHOe53/Oc2+e5AEURXm8fp8/RG83lLSnFjRN+1YIp3vhjwWfPxgGEKFYPA4hla4kISQhCSEkSTKleWIQUiSEFKQghCCaiONBnzHgnV+NMDKKucUfJEyWcfO4dRS3DPxq8AcCqRBECCJEpTyNwPZ704xCNEIwkUAJElG7JSEaBDc3nBFiJhJyRghnlHBGCFc0xBALu2LhmVh4bi0SIeOOcGA6EnRFQ79HCEc44IwQ3vVV8MXkg4yOusMqwcsqAUvJe0nJy1Vwc5LkKrj5ytaDvS1f2X47PdhRqBIcUDTn9bScHujI6m44O9IPqnAdaK95sZdXqOLn97UeVgkyZbVAzAGS2y9I7uyXN+6R1bJNugqT9phGCEQ1x9T3vrQ9AGLOO0OdoHPBdXakn41jH5l0FTj2Ma5j41ilWX/JMnDJMsDGsctjg6J5JxvHKnDsYvKw0qw/b9LeddnA1w7jcZ3ozcGOUr20WCcpwSRletkpTFqkERZphEeTvlDFP/p4XaQWvqZpL1DxK80YqDJq9nXW7+u4m9fbnC1vyJE3Hurj5yqaQPutNGrStxmiGiD4/gwmAZ0eV7lRU5WssNKMVeLYhWTlV8YGn8XVsSE2rqubtYFvpvAj6raTmOS4VnRSJy7WiUswyQmd+BV127M4ohGylLxPzHpwcVSdp2hi9XCz5Y15PS0Ffa2FKkGOggtEHCCq2ZUMMQfcu/EWJgWieed7Bvk5o6bcqHns1edG1eVGzXmj5kOTdicXcN0HIyqOcxzcmLa8PdT1rqG7TC99HZOW6qUlmPSNAVmZXlaqlxUnP+QpTmGSV/vbPh0bAldMWlZvy6Eebj7TXq25Cm62ovGAgstS8lhKHvNj234AwpvpZLTfAvzvzujEQO6ZvTo29PnE/WtWw2cTw9ethuvWlDdUTwxXb51so9o6fNky2Oi2A2PA95NrsnnO0Tzn4KZwM+smt73OZatz2Rpm7Vy3I51mt6Pe9VDrnWfKBrxvM0WcbV0hvJkhuXOwtyVbwd37y49Mtbs2yTWrAUhuZ3U37Omqf0JmV31Wd0OBip+vbN2vaMrsrEuP7u3+Gchq37/fA3wba5MhvyMcsG/nYchvJZatxIot5LfviE6G/H+uRgH9HPa/+L+J0Y5Z82+2z/cyQigOoW99lUrOEUTTCZKcejSdIEnEjA1EQrjiD2zG44imIZNCR6MxkoQUQszLGxBaiWUSbc26jY1NeU/f6tpaahuPx+2OR7FYLFkwc2J3TIUjka2ylzfX3bEwEd9MZQeDhMezmBpuCKElr29xyetZXCITidTVM65Zr++vf8R/Az2IwTMW9SE8AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Too many primary buttons confuse users.",
              "title": "",
              "src": "/static/bbc8700f9912c0762b652dd5de1990ff/37523/Too-many-primary-actions.png",
              "srcSet": ["/static/bbc8700f9912c0762b652dd5de1990ff/679d2/Too-many-primary-actions.png 186w", "/static/bbc8700f9912c0762b652dd5de1990ff/67a5d/Too-many-primary-actions.png 373w", "/static/bbc8700f9912c0762b652dd5de1990ff/37523/Too-many-primary-actions.png 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
        <p>{`Don’t fill up the screen with primary actions.`}</p>
      </DontImage>
    </GuidelineImages>
    <GuidelineImages mdxType="GuidelineImages">
      <DoImage mdxType="DoImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "318px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "15.053763440860216%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6klEQVQI1yWOO0/CYBSGGwNUES+IF36AsxhJHOUfYGJCDANGBwfj7g5tgQKTiwgDOnmL0Bj8Tz3Hln51NOG85tP1yfNeDLJNhzub8BsJIdsUaq2I30jAry+AWqugZgbkpEFWCuxm/5mVBNkmyFnSXMhJCzWXdcYzyF5E7F1AvZ3O1bgGNTmDeq0gej5G9FSGmtQQe+eIXk4wGx0hnl5pF7OHEr4/r6Heq4g/LkV36GHDt5I31F7/4e42uLsl1F4T7u3g63b37x27OXAnB3Y3wL08wuEhgn4Bwd0ewkER4f2BhIOiBP197T/+ArwJnTfG39SUAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "One primary button on a screen stands out.",
              "title": "",
              "src": "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png",
              "srcSet": ["/static/98ff1badb4cd702981158d74d2218abb/679d2/Badge-w-action.png 186w", "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png 318w"],
              "sizes": "(max-width: 318px) 100vw, 318px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
        <p>{`Ensure there’s only one primary action on each screen.`}</p>
      </DoImage>
      <DontImage mdxType="DontImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "318px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "15.053763440860216%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6klEQVQI1yWOO0/CYBSGGwNUES+IF36AsxhJHOUfYGJCDANGBwfj7g5tgQKTiwgDOnmL0Bj8Tz3Hln51NOG85tP1yfNeDLJNhzub8BsJIdsUaq2I30jAry+AWqugZgbkpEFWCuxm/5mVBNkmyFnSXMhJCzWXdcYzyF5E7F1AvZ3O1bgGNTmDeq0gej5G9FSGmtQQe+eIXk4wGx0hnl5pF7OHEr4/r6Heq4g/LkV36GHDt5I31F7/4e42uLsl1F4T7u3g63b37x27OXAnB3Y3wL08wuEhgn4Bwd0ewkER4f2BhIOiBP197T/+ArwJnTfG39SUAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Too many primary buttons confuse users.",
              "title": "",
              "src": "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png",
              "srcSet": ["/static/98ff1badb4cd702981158d74d2218abb/679d2/Badge-w-action.png 186w", "/static/98ff1badb4cd702981158d74d2218abb/cd2d9/Badge-w-action.png 318w"],
              "sizes": "(max-width: 318px) 100vw, 318px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
        <p>{`Don’t fill up the screen with primary actions.`}</p>
      </DontImage>
    </GuidelineImages>
    <h3>{`Guidelines side by side`}</h3>
    <GuidelinesSideBySide mdxType="GuidelinesSideBySide">
      <Do mdxType="Do">
        <ul>
          <li parentName="ul">{`My profile, My trips, Preferences`}</li>
          <li parentName="ul">{`My trips, Upcoming, Completed`}</li>
          <li parentName="ul">{`Guidelines, React, iOS, Android`}</li>
        </ul>
      </Do>
      <Dont mdxType="Dont">
        <ul>
          <li parentName="ul">{`Your user profile, Trips, Settings for your account`}</li>
          <li parentName="ul">{`My trips, Trips you have planned, Trips you have taken`}</li>
          <li parentName="ul">{`Some general thoughts, Specifics for React, iOS implementation, How it’s done in Android`}</li>
        </ul>
      </Dont>
    </GuidelinesSideBySide>
    <GuidelinesSideBySide mdxType="GuidelinesSideBySide">
      <Do mdxType="Do">
        <ul>
          <li parentName="ul">
            <p parentName="li">{`As the passenger, it’s your responsibility to ensure
you have the correct visas and travel documents for your trip.`}</p>
            <p parentName="li">{`You should carefully check which countries you’re transiting through before making your booking.
A visa is generally required even for transit or rechecking your baggage.`}</p>
          </li>
        </ul>
      </Do>
      <Dont mdxType="Dont">
        <ul>
          <li parentName="ul">
            <ul parentName="li">
              <li parentName="ul">{`As a passenger, it’s your responsibility to ensure
you have the correct visas and travel documents for your trip.`}</li>
              <li parentName="ul">{`You should carefully check which countries you’re transiting through before making your booking.`}</li>
              <li parentName="ul">{`A visa is generally required even for transit or rechecking your baggage.`}</li>
            </ul>
          </li>
        </ul>
      </Dont>
    </GuidelinesSideBySide>
    <h2>{`Inline token`}</h2>
    <p>{`TODO.`}</p>
    <h2>{`Color palette`}</h2>
    <h3>{`Plain palette`}</h3>
    <Palette colors={["paletteProductLight", "paletteProductNormal", "paletteProductDark", "paletteProductDarker"]} mdxType="Palette" />
    <h3>{`Palette with additional shades`}</h3>
    <Palette allowAdditional colors={["paletteProductLight", "paletteProductNormal", "paletteProductDark", "paletteProductDarker"]} mdxType="Palette" />
    <h2>{`React examples`}</h2>
    <ReactExample exampleId="Wizard-default" mdxType="ReactExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      